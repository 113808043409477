@import '../../styles/meta/index';

.ui-scrollable-area {
    position: relative;
    z-index: 0;

    &__scroll-shadow {
        position: absolute;
        top: -8px;
        left: 0;
        right: 0;
        height: 8px;
        box-shadow: 0 2px 3px #dddddd;
        opacity: 0;
        transition: opacity 500ms ease-in-out;
        pointer-events: none;

        &--active {
            opacity: 1;
            z-index: 1;
        }
    }

    &__content {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        &--h-scroll {
            overflow-x: auto;
        }

        &--h-thick::-webkit-scrollbar {
            height: 9px;
        }
    }
}
