@import 'modules/styles/meta/index';

.rpt-number-range-filter {
    display: flex;
    align-items: center;
    @include font-standard(13px, #777d81);

    &__above-text {
        margin-right: 5px;
    }

    &__above-editor {
        width: 110px;
    }

    &__below-text {
        margin: 0 5px;
    }

    &__below-editor {
        width: 110px;
    }
}
