@import 'modules/styles/meta/index';

$side-margin: 60px;

.reql-section-container {
    padding: 0 $side-margin;
    display: flex;
    flex-flow: column;

    &--no-padding {
        padding: 0;
    }

    &__header {
        flex: none;
        height: 40px;
        line-height: 40px;
        @include font-standard-semibold(14px, #000);
        text-transform: uppercase;
    }

    &__body {
        overflow: hidden;
    }
}
