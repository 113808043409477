@import 'modules/styles/meta/index';

.invitations-list-block {
    display: flex;
    flex-flow: column;
    width: 680px;
    margin-bottom: 40px;

    &__inv-list-header {
        display: flex;
        align-items: center;
    }

    &__inv-list-title {
        @include font-standard-semibold(20px, #333);
        text-transform: uppercase;
        flex: 1;
    }

    &__inv-list-list {
        display: flex;
        flex-flow: column;
        margin-top: 20px;
    }

    &__inv-list-item {
        display: flex;
        align-items: center;
        height: 60px;

        &:not(:last-child) {
            border-bottom: 1px solid #cacccd;
        }
    }

    &__inv-list-item-content {
        flex: 1;
        display: flex;
        flex-flow: column;
        margin-right: 15px;
        overflow: hidden;
    }

    &__inv-list-item-title {
        @include font-standard-semibold(14px, #000);
        @include u-text-ellipsis;
    }

    &__inv-list-item-description {
        @include font-standard(12px, #6c6a6a);
        @include u-text-ellipsis;
    }

    &__inv-list-item-action {
        @include font-standard(12px, #000);
    }
    &__inv-list-block {
        display: flex;
        flex-flow: column;
        width: 680px;
        margin-bottom: 40px;
    }

    &__inv-list-header {
        display: flex;
        align-items: center;
    }

    &__inv-list-title {
        @include font-standard-semibold(20px, #333);
        text-transform: uppercase;
        flex: 1;
    }

    &__inv-list-list {
        display: flex;
        flex-flow: column;
        margin-top: 20px;
    }

    &__inv-list-item {
        display: flex;
        align-items: center;
        height: 60px;

        &:not(:last-child) {
            border-bottom: 1px solid #cacccd;
        }
    }

    &__inv-list-item-content {
        flex: 1;
        display: flex;
        flex-flow: column;
        margin-right: 15px;
        overflow: hidden;
    }

    &__inv-list-item-title {
        @include font-standard-semibold(14px, #000);
        @include u-text-ellipsis;
    }

    &__inv-list-item-description {
        @include font-standard(12px, #6c6a6a);
        @include u-text-ellipsis;
    }

    &__inv-list-item-action {
        @include font-standard(12px, #000);
    }
}
