@import 'modules/styles/meta/index';

.rpt-paginator {
    display: flex;
    align-items: center;

    &__prev-next-button {
        @include font-standard-semibold(12px, #fff);
        background: #6ba67a;
        padding: 5px 15px;
        cursor: pointer;

        &--disabled {
            background: #f1f0f0;
            color: #a0a1a1;
            cursor: default;
        }

        &:not(&--disabled):hover {
            background: #397f4a;
        }

        &:not(&--disabled):focus {
            background: #397f4a;
            color: rgba(255, 255, 255, 0.6);
        }
    }

    &__buttons-section {
        margin: 0 10px;
        display: flex;
    }

    &__page-button {
        @include font-standard-semibold(12px, #30373d);
        background: #f1f1f1;
        padding: 5px 10px;
        cursor: pointer;

        &--active {
            background: #64a174;
            color: #fff;
            cursor: default;
        }

        &:not(&--active):hover {
            background: #cacccd;
        }
    }

    &__page-ellipsis {
        @include font-standard-semibold(12px, #30373d);
        padding: 5px 5px;
    }
}
