@import 'modules/styles/meta/index';

.common-ui-help-section {
    height: 40px;
    padding: 0 60px;
    display: flex;
    align-items: center;
    background: #f3f3f3;

    &__dropdown-button {
        margin-right: 20px;
        @include font-standard-semibold(12px, #477753);
    }

    &__support-dropdown-button {
        border-left: 1px solid #caccce;
        padding-left: 20px;
        @include font-standard-semibold(12px, #477753);
    }

    &__link-item {
        @include font-standard(12px, #000);
        display: block;
        text-decoration: none;
    }

    &__link-item:hover,
    &__link-item:active {
        @include font-standard(12px, #000);
        text-decoration: none;
    }

    &__training-dropdown-panel {
        @include o-dropdown-panel();
        width: 260px;
        padding: 15px 20px;
    }

    &__training-dropdown-panel-title {
        @include font-standard-semibold(12px, #000);
    }

    &__training-dropdown-panel-description {
        @include font-standard(12px, #000);
        margin-top: 5px;
        margin-bottom: 20px;
    }

    &__close-button-wrp {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__close-button {
        margin-left: 10px;
        display: flex;
        align-items: center;
        @include font-standard(12px, #384650);
        fill: #777777;

        &:hover {
            fill: #d43f3a;
            color: #d43f3a;
        }
    }

    &__close-button-icon {
        margin-top: 1px;
        margin-right: 4px;
    }

    &__close-button-text {
    }
}
