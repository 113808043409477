@import '../../styles/meta/index';
@import 'index';

.ui-data-table-checkbox-column-header {
    @include data-row-cell-padding();

    &__checkbox {
        display: flex;
        align-items: center;
        height: 100%;
    }
}
