@import '../../styles/meta/index';

.ui-or-separator {
    display: flex;
    align-items: center;

    &__or-separator-line {
        flex: 1;
        @include o-popup-border-bottom;
        height: 1;
    }

    &__or-separator-text {
        flex: none;
        @include font-standard(13px, #6c6a6a);
        padding: 0 22px;
    }
}
