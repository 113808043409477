@import './utils';
@import './animations';
@import './objects';
@import './colors';
@import './adaptive';

@mixin font-standard($size: null, $color: null) {
    font-weight: 400; // =normal
    @if ($size) {
        font-size: $size;
    }
    @if ($color) {
        color: $color;
    }
}

@mixin font-standard-semibold($size: null, $color: null) {
    font-weight: 600;
    @if ($size) {
        font-size: $size;
    }
    @if ($color) {
        color: $color;
    }
}

@mixin font-standard-bold($size: null, $color: null) {
    font-weight: 700; // =bold
    @if ($size) {
        font-size: $size;
    }
    @if ($color) {
        color: $color;
    }
}

@mixin font-standard-light($size: null, $color: null) {
    font-weight: 300; // =light
    @if ($size) {
        font-size: $size;
    }
    @if ($color) {
        color: $color;
    }
}
