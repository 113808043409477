@import 'modules/styles/meta/index';

.profile-editable-avatar {
    width: 120px;
    height: 120px;
    background: #fafafa;
    border: solid 1px #cacccd;
    border-radius: 50%;
    cursor: pointer;

    &:hover,
    &--over {
        border-color: #477753;
    }

    &__avatar-dropzone-content {
        @include u-flexCenter();
        height: 100%;
        width: 100%;
        position: relative;
    }

    &__avatar-edit-overlay {
        @include u-flexCenter();
        @include u-posAbsolute($left: -1px, $top: -1px, $bottom: -1px, $right: -1px);
        background: #333333;
        border-radius: 50%;
        opacity: 0;
    }

    &__avatar-dropzone:hover &__avatar-edit-overlay {
        opacity: 0.9;
    }
}
