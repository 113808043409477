@import '../../styles/meta/index';

.ui-action-menu {
    &__panel {
        @include o-dropdown-panel();
        padding: 5px 0;
        min-width: 160px;
        font-size: 14px;
    }
}
