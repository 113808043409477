@import 'modules/styles/meta/index';

.common-ui-toast-notifications {
    position: absolute;
    bottom: 30px;
    left: 20px;
    right: 20px;
    z-index: 999999;
    display: flex;
    flex-flow: column;
    align-items: center;

    @media screen and (min-width: 760px) {
        left: 220px;
        right: 220px;
    }

    &__toast-item {
        padding: 10px 20px;
        text-align: center;
        margin-bottom: 6px;

        &--info {
            @include font-standard(14px, #fff);
            background: #464646;
            box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);

            a {
                color: #acdeb9;
                text-decoration: underline;
            }
        }

        &--error {
            @include font-standard(14px, #fff);
            background: #9b464f;
            box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);

            a {
                color: #fff;
            }
        }
    }
}
