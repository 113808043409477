@import '../../../styles/meta/index';

.form-dropdown-editor-panel {
    @include o-dropdown-panel(0);
    min-width: 200px;
    font-size: 12px;
    color: #000;

    &__list {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 200px;

        &--no-limit-height {
            max-height: none;
        }
    }

    &__add-new-button {
        @include u-text-ellipsis();
        max-width: 100%;
        border-top: solid 1px #cacccd;
        padding: 8px 10px;
        line-height: 1.4;
        cursor: pointer;

        &:hover {
            color: #3c763d;
        }
    }

    &__has-more {
        @include u-text-ellipsis();
        max-width: 100%;
        padding: 8px 10px;
        line-height: 1.4;
        color: #777d81;
        cursor: default;
    }

    &__empty-list {
        @include u-flexCenter();
        @include font-standard();
        padding: 10px 20px;
    }
}
