@import 'modules/styles/meta/index';

.common-ui-avatar {
    overflow: hidden;
    border-radius: 50%;
    vertical-align: middle;
    background: #e8e8e8 no-repeat 50% 50%;
    background-size: cover;
    @include u-flexCenter();
}
