@import 'modules/styles/meta/index';

.int-xero-integration-select-popup {
    width: 840px;
    height: 520px;

    &__content {
        @include o-popup-padding;
        padding-bottom: 60px;
        display: flex;
        flex-flow: column;
    }

    &__message {
        @include font-standard-semibold(13px, #000);
    }

    &__warning {
        margin-top: 20px;
        padding: 15px 20px 15px 22px;
        display: flex;
        align-items: center;
        background: #f3f3f3;
    }

    &__warning-icon {
        margin-right: 15px;
    }

    &__warning-text {
        @include font-standard(13px, #000);
    }

    &__xero-org-block {
        margin-top: 30px;
    }
}
