@import 'modules/styles/meta/index';

.no-companies-page-create-org-button {
    width: 213px;
    height: 128px;
    display: flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
    border: 1px solid #4c8a5c;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:not(:last-child) {
        margin-right: 20px;
    }

    &__action-button-text {
        margin-top: 25px;
        @include font-standard-semibold(13px, #000);
    }

    &__action-button-icon {
        margin-top: 24px;
    }
}
