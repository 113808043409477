@import 'modules/styles/meta/index';

.company-select-rb-subscription-popup {
    width: 840px;
    height: 520px;
    display: flex;
    flex-flow: column;

    &__header {
        height: 80px;
        display: flex;
        align-items: center;
        padding: 0 60px;
    }

    &__header-text {
        flex: 1;
        @include font-standard-semibold(18px, #333);
    }

    &__body-wrp {
        flex: 1;
        overflow: hidden;
    }

    &__body {
        display: flex;
        flex-flow: column;
        padding: 10px 60px 50px 60px;
        overflow-y: auto;
    }

    &__subscriptions-panel {
        display: flex;
        flex-flow: column;
    }

    &__subscriptions-title {
        @include font-standard-semibold(13px, #000);
        margin-bottom: 20px;
    }

    &__no-subscriptions-panel {
        background: #f3f3f3;
        @include font-standard(13px, #000);
        margin-bottom: 30px;
        padding: 15px 20px;
    }

    &__nfp-panel {
        margin-top: 30px;
        padding: 15px 20px 20px 20px;
        border: 1px solid #dfdfdf;
    }

    &__nfp-title {
        @include font-standard-semibold(13px, #000);
        margin-bottom: 5px;
    }

    &__nfp-description {
        @include font-standard-semibold(12px);
    }
    &__subscriptions-area {
        display: flex;
        flex-flow: column;
    }
}
