@import './utils';

@mixin o-permanent-underline($color: #999, $height: 1px) {
    position: relative;

    &::before {
        @include u-posAbsolute($left: 0, $bottom: 0, $right: 0, $height: $height);
        content: '';
        background: $color;
    }
}

@mixin o-sliding-underline($color: #4c8b5d, $height: 2px, $activeClassName: 'active', $vOffset: 0px) {
    position: relative;
    padding-bottom: $height;

    &::after {
        @include u-posAbsolute($left: 0, $bottom: -$vOffset, $height: $height, $width: 0);
        content: '';
        background: $color;
        transition: width 0.4s;
    }

    &:focus::after,
    &--#{$activeClassName}::after {
        width: 100%;
    }
}

@mixin o-dropdown-panel($offset: 2px) {
    margin: $offset 0;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

@mixin o-triangle-up($size, $color) {
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    border-top: none;
    position: relative;
    z-index: 1;
}

@mixin o-triangle-down($size, $color) {
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    border-bottom: none;
}

@mixin o-triangle-right($size, $color) {
    width: 0;
    height: 0;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    border-right: none;
}

@mixin o-triangle-left($size, $color) {
    width: 0;
    height: 0;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    border-left: none;
}

@mixin o-disabled() {
    opacity: 0.7;
    cursor: default;
}

@mixin o-form-input {
    border: solid 1px #cacccd;
    border-radius: 2px;
    padding: 3px;
    width: 100%;
    background-color: #fff;
    cursor: pointer;

    &::-ms-clear {
        display: none;
    }

    &:focus,
    &--focused,
    &--active {
        cursor: auto;
    }
    &:focus:not(&--invalid),
    &--focused:not(&--invalid),
    &--active:not(&--invalid) {
        border-color: #565656;
    }

    &--disabled {
        color: #a0a1a1;
        background-color: #f8f5f5;
        cursor: default;
    }

    &--invalid {
        background-color: #fff5f3;
        border-color: #f99178;
        color: #923924;

        &::placeholder {
            color: #923924;
        }
    }
}

@mixin o-transparent-input {
    border: none;
    padding: 3px;
    background-color: transparent;
    width: 100%;
    cursor: pointer;

    &::-ms-clear {
        display: none;
    }

    &:focus,
    &--active {
        cursor: auto;
    }

    &--disabled {
        color: #a0a1a1;
        background-color: #f8f5f5;
        cursor: default;
    }

    &--invalid {
        background-color: #fff5f3;
        color: #923924;
    }
}

@mixin o-popup-border-bottom() {
    border-bottom: 1px solid #ebeaea;
}

@mixin o-popup-border-top() {
    border-top: 1px solid #ebeaea;
}

@mixin o-popup-padding-left($extra) {
    padding-left: 80px + $extra;
}

@mixin o-popup-padding-right($extra) {
    padding-right: 60px + $extra;
}

@mixin o-popup-padding-h($left-extra: 0, $right-extra: 0) {
    @include o-popup-padding-left($left-extra);
    @include o-popup-padding-right($right-extra);
}

@mixin o-popup-padding-top() {
    padding-top: 10px;
}

@mixin o-popup-padding-bottom() {
    padding-bottom: 40px;
}

@mixin o-popup-padding-v() {
    @include o-popup-padding-top();
    @include o-popup-padding-bottom();
}

@mixin o-popup-padding() {
    @include o-popup-padding-h();
    @include o-popup-padding-v();
}

@mixin o-popup-border-right() {
    border-right: 1px solid #ebeaea;
}

@mixin o-transparent-link($color: inherit) {
    text-decoration: none;
    color: $color;

    &:link,
    &:hover,
    &:active,
    &:visited {
        text-decoration: none;
        color: $color;
    }
}

@mixin o-button() {
    cursor: pointer;
    user-select: none;
}

@mixin o-focus() {
    box-shadow: 0 0 1px 2px lighten(#4c8a5c, 30%);
}
