@import '../../../styles/meta/index';

.form-checkbox-editor--t-form {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 12px;

    &--disabled {
        cursor: default;
    }

    &--align-top {
        align-items: flex-start;
    }

    &--align-bottom {
        align-items: flex-end;
    }

    &__checkbox {
        flex: none;
    }

    &__content {
        flex: 1 0 0;
        cursor: pointer;

        &:empty {
            display: none;
        }
    }
}
