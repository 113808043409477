@import '../../styles/meta/index';

.ui-transparent-button {
    cursor: pointer;
    user-select: none;

    &--disabled {
        cursor: default;
    }
}
