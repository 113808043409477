@import '../../../../styles/meta/index';

.ui-default-popup-content-button {
    @include font-standard-semibold(16px, #4c8a5c);
    @include u-text-ellipsis();
    max-width: 100%;
    text-transform: uppercase;
    transition: color 200ms;
    cursor: pointer;
    user-select: none;

    &:disabled {
        @include o-disabled();
    }

    &:not(:disabled):hover {
        color: #286538;
    }
}
