@import 'modules/styles/meta/index';

.reql-line-item-footer-total {
    display: flex;
    align-items: center;

    &__label {
        @include font-standard-semibold(11px, #777d81);
    }

    &__value {
        width: 100px;
        text-align: right;
        @include font-standard-semibold(13px, #000);
    }
}
