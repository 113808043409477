@import 'modules/styles/meta/index';

.reqf-file-item {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    border: 1px solid #cacccd;
    border-radius: 4px;
    cursor: pointer;
    padding: 0 5px 0 10px;
    height: 36px;

    & + & {
        margin-top: 10px;
    }

    &--loading {
        border-color: transparent;
        padding-right: 120px;
    }

    &__paper-clip-icon {
        color: #a0a1a1;
        fill: currentColor;
        flex: none;
    }

    &__name {
        flex: 1;
        margin: 0 10px 0 6px;
        @include font-standard(13px, #000);
        @include u-text-ellipsis;
    }

    &__delete-button {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        transition: opacity 300ms;
        fill: #a4a1a1;
        z-index: 2;
        padding: 5px;

        &:hover {
            fill: #cb4e30;
        }
    }

    &:hover:not(&--loading) &__delete-button {
        opacity: 1;
        visibility: visible;
    }

    /* Hover state */

    &__preview-panel {
        @include u-posAbsoluteFill();
        @include u-flexCenter();
        z-index: 1;
        background: rgba(242, 240, 240, 0.85);
        border-radius: 4px;
        visibility: hidden;
    }

    &:hover:not(&--loading) &__preview-panel {
        visibility: visible;
    }

    &--active &__preview-panel {
        visibility: visible;
    }

    /* Loading state */

    &__loading-panel {
        @include u-posAbsoluteFill();
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: rgba(242, 240, 240, 0.6);
        border-radius: 4px;
        cursor: default;
        z-index: 1;
        visibility: hidden;
    }

    &__loading-panel-spinner {
        margin-bottom: 2px;
    }

    &__loading-panel-text {
        margin-left: 5px;
        margin-right: 10px;
        @include font-standard(13px, #7f7d7d);
        @include u-text-ellipsis;
    }

    &--loading &__loading-panel {
        visibility: visible;
    }
}
