@import '../../styles/meta/index';

.ui-data-row {
    display: flex;
    height: 50px;

    &--auto-height {
        height: auto;
        min-height: 50px;
    }

    &--clickable {
        cursor: pointer;
    }

    &:hover {
        background: #e9eef0;
    }

    &[data-is-invalid='true'] {
        background: #fff5f3;
        color: #923924;
    }

    &__cell {
        flex: 1 0 50px;
        display: flex;
        align-items: center;
        overflow: hidden;

        &--fixed {
            flex: none;
        }
    }
}
