.global-progress {
    height: 4px;
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background: #4c8b5d;

    &__bar {
        transform: scaleX(0);
        transform-origin: right center;
        animation: indeterminate-bar 2s linear infinite;
        background-color: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        will-change: transform;

        &::after {
            content: '';
            transform-origin: center center;
            animation: indeterminate-splitter 2s linear infinite;
            background: #4c8b5d;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}

@keyframes indeterminate-bar {
    0% {
        transform: scaleX(1) translateX(-100%);
    }
    50% {
        transform: scaleX(1) translateX(0%);
    }
    75% {
        transform: scaleX(1) translateX(0%);
        animation-timing-function: cubic-bezier(0.28, 0.62, 0.37, 0.91);
    }
    100% {
        transform: scaleX(0) translateX(0%);
    }
}

@keyframes indeterminate-splitter {
    0% {
        transform: scaleX(0.75) translateX(-125%);
    }
    30% {
        transform: scaleX(0.75) translateX(-125%);
        animation-timing-function: cubic-bezier(0.42, 0, 0.6, 0.8);
    }
    90% {
        transform: scaleX(0.75) translateX(125%);
    }
    100% {
        transform: scaleX(0.75) translateX(125%);
    }
}
