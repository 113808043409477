$break-mobile-screen: 600px;
$break-small-desktop-screen: 1250px;

@mixin if-mobile-screen {
    @media only screen and (max-width: $break-mobile-screen) {
        @content;
    }
}

@mixin if-mobile-screen-landscape {
    @media only screen and (max-height: $break-mobile-screen) {
        @content;
    }
}

@mixin if-desktop-screen {
    @media only screen and (min-width: $break-mobile-screen) {
        @content;
    }
}

@mixin if-small-desktop-screen {
    @media only screen and (max-width: $break-small-desktop-screen) {
        @content;
    }
}

@mixin if-ios {
    html.ios & {
        @content;
    }
}

@mixin if-not-ios {
    html:not(.ios) & {
        @content;
    }
}

@mixin if-mobile-device {
    html.mobile & {
        @content;
    }
}

@mixin if-not-mobile-device {
    html:not(.mobile) & {
        @content;
    }
}
