@import 'modules/styles/meta/index';

.rq-new-request-popup {
    height: 600px;
    width: 900px;

    &--empty {
        width: 530px;
        height: auto;
    }

    &__content {
        flex: 1;
        display: flex;
        flex-flow: column;
        overflow: hidden;
    }

    &__search-section {
        flex: none;
        padding: 5px 60px 10px 80px;
    }

    &__list-section {
        flex: 1;
        display: flex;
        flex-flow: column;
        overflow-y: auto;
    }

    &__list-item {
        flex: none;
        display: flex;
        flex-flow: column;
        padding: 0 10px;
        cursor: pointer;

        &:hover:not(&--selected):not(&--disabled) {
            background: #fafafa;
        }

        &--selected {
            background: #f0efef;
        }

        &--disabled {
            background: #f1f4f4;
            cursor: default;
        }
    }

    &__list-item-radio-wrp {
        flex: 0 0 40px;
        width: 40px;
    }

    &__list-item-radio {
    }

    &__list-item-content {
        display: flex;
        align-items: center;
        min-height: 60px;
    }

    &__list-item-title {
        @include font-standard-semibold(14px, #000);
        margin: 10px 10px 10px 0;
        flex-grow: 0;
        flex-shrink: 0;

        &--disabled {
            color: #777d81;
        }
    }

    &__list-item-company {
        @include font-standard(14px, #7f7d7d);
        margin: 10px 10px 10px 0;
        flex-grow: 0;
        flex-shrink: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__list-item-disabled-template-badge {
        flex: none;
        @include font-standard-bold(11px, #a0a1a1);
        display: flex;
        align-items: center;
        border: 2px solid #cacccd;
        height: 26px;
        text-transform: uppercase;
        padding: 0 10px;
        margin-right: 5px;
    }

    &__empty-content {
        display: flex;
        flex-flow: column;
    }

    &__empty-header {
        display: flex;
        flex-flow: column;
        align-items: center;
        flex: none;
    }

    &__empty-header-title {
        @include font-standard-semibold(16px, #000);
        text-transform: uppercase;
        text-align: center;
    }

    &__empty-header-description {
        @include font-standard(13px, #565656);
        text-align: center;
        margin-top: 20px;
        margin-bottom: 5px;
    }

    &__empty-footer {
        overflow-y: auto;
        max-height: 500px;
    }

    &__empty-footer-companies {
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: 5px 20px 60px 20px;
    }

    &__empty-footer-company-item {
        @include font-standard-semibold();
        @include u-text-ellipsis();
        max-width: 100%;
        margin: 5px 0;
    }

    &__empty-footer-managers {
        padding: 20px 20px 60px 20px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    &__empty-footer-manager-item {
        cursor: pointer;
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: 20px 20px;
    }

    &__empty-footer-manager-item-name {
        margin-top: 15px;
        @include font-standard-semibold(13px, #000);
    }
}
