@import '../../../styles/meta/index';

.form-dropdown-editor-list-item {
    @include font-standard(12px, #000);
    overflow: hidden;
    line-height: 18px;
    padding: 6px 10px;
    cursor: pointer;
    border-bottom: 1px solid #ebeaea;

    &:hover {
        background: #f5f5f5;
    }

    &--active {
        background: #f5f5f5;
    }

    &__text-with-subtext {
        display: flex;
        justify-content: space-between;
    }

    &__text {
        white-space: normal;
    }

    &__sub-text {
        color: #6c6a6a;
        margin-left: 10px;
    }

    &--single-line-per-item {
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__highlighted-text {
        background-color: #edecd0;
    }
}
