@import 'modules/styles/meta/index';

.common-ui-user-hover {
    &__panel {
        @include o-dropdown-panel();
        min-width: 300px;
        max-width: 400px;

        &--loading {
            @include u-flexCenter();
            height: 125px;
        }
    }

    &__header {
        height: 82px;
        padding: 0 24px;
        background: #f4f4f4;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #d5d4d4;
    }

    &__avatar {
        width: 64px;
    }

    &__inactive-user-avatar {
        @include u-flexCenter();
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #e8e8e8;
    }

    &__display-name {
        @include font-standard(17px, #333);
        @include u-text-ellipsis();
        flex: 1;
    }

    &__list {
        padding: 8px 0 10px 0;
    }

    &__row {
        display: flex;
        padding: 0 24px;
        align-items: center;
        height: 33px;
    }

    &__col-left {
        @include font-standard(11px, #999);
        width: 64px;
    }

    &__col-right {
        @include font-standard(13px, #478a60);
        @include u-text-ellipsis();
        flex: 1;
    }

    &__footer {
        padding: 18px 24px;
        border-top: 1px solid #cacccd;
        @include font-standard(12px, #7d7c7c);
    }
}
