@import '../../../styles/meta/index';

%form-number-editor {
    text-overflow: ellipsis;
}

.form-number-editor--t-form {
    @extend %form-number-editor;
    @include o-form-input;

    &--align-right {
        text-align: right;
    }
}

.form-number-editor--t-transparent {
    @extend %form-number-editor;
    @include u-transparent-input();

    &--align-right {
        text-align: right;
    }

    &--invalid::placeholder {
        color: #923924;
    }

    &--warning::placeholder {
        color: #ffc107;
    }
}
