/*

  When adding a new color, consider reading this first:
  https://davidwalsh.name/sass-color-variables-dont-suck

  Then, to add a new color (if you really need to do this rather than darken()/lighten() for example),
  name it using this tool:
  http://chir.ag/projects/name-that-color/#4C8B5D

*/

$color-forest-green: #4c8b5d;
$color-terra-cotta: #cb4e30;
