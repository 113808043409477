@import 'modules/styles/meta/index';

.reql-field-entry {
    display: flex;
    flex-flow: column;

    &__field {
        @include font-standard-semibold(11px, #777d81);
        @include u-text-ellipsis;
        margin-bottom: 3px;
    }

    &__value {
        @include font-standard(13px, #000);
        white-space: pre-line;

        &--warning {
            color: #b53415;
            font-weight: 600;
        }
    }

    &__warning-text {
        @include font-standard(11px, #000);
        padding-top: 5px;
        max-width: 150px;
    }

    &__rich-editor {
        border: 1px solid #cacccd;
        padding: 5px;
        margin: 5px 0;
    }
}
