@import 'modules/styles/meta/index';

$side-margin: 60px;

.reql-request-note-section {
    display: flex;
    justify-content: space-between;
    padding: 0 $side-margin - 10px 0 $side-margin;
    margin-bottom: 20px;

    &__field {
        display: flex;
        flex-flow: column;
        flex: 1;
        margin-bottom: 10px;
        min-width: 120px;
        max-width: 100%;
    }

    &__label {
        @include font-standard-semibold(11px, #777d81);
        @include u-text-ellipsis;
        margin-bottom: 3px;
    }
}
