@import '../../styles/meta/index';

.ui-raw-avatar {
    overflow: hidden;
    border-radius: 50%;
    vertical-align: middle;
    background: #fff no-repeat 50% 50%;
    background-size: cover;

    &--abbreviation {
        @include font-standard();
        color: #777d81;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
        background-color: #e8e8e8;
    }

    &--image {
    }
}
