@import '../../../styles/meta/index';

@keyframes form-dropdown-editor-button__loader-spin-360 {
    100% {
        transform: rotate(360deg);
    }
}

.form-dropdown-editor-button--t-form {
    position: relative;

    &--loading::after {
        @include u-posAbsolute($top: 5px, $right: 6px, $width: 16px, $height: 16px);
        content: '';
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAArklEQVQ4y2NgwAGYmJgkgNRdEIaySQOMjIxeQOo/CEPZBMFCIP4KVBwC4rCysrIAqXYQhrJhBtswMzNzYDPgK9TG+QQsegwyBJuTQTbPB9IqJPuXIgAKYVAgIfuTVHAX6vd2YjUAA5EPSB0GWuxGuQHU8AJ1ALHRCHI20NVC2OQIJiRooIHUzMYmj5KUoRrCQBjGh9o8GyhmR9BLQMVyUNv+Q9mkAWisrAZhfDEEAA5cIiN3OTV6AAAAAElFTkSuQmCC');
        animation: form-dropdown-editor-button__loader-spin-360 1000ms infinite linear;
        z-index: 1;
    }
    &--loading::before {
        @include u-posAbsolute($top: 1px, $right: 1px, $width: 22px, $height: 21px);
        content: '';
        background: #fff;
        z-index: 1;
    }

    &__display-text {
        @include o-form-input();
        @include u-text-ellipsis();
        visibility: hidden;

        /* defines button height since input is absolute */
        &::after {
            content: ' ';
            display: inline-block;
        }

        &--visible {
            visibility: visible;
        }
    }

    /* IE11 hack, must mimic ordinary placeholder */
    &__input-placeholder {
        line-height: 19px;
        padding: 4px 4px 4px 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
        color: #a3a3a3;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        &--invalid {
            color: #923924;
        }

        &--warning {
            color: #ffc107;
        }
    }

    &__input {
        @include o-form-input();
        text-overflow: ellipsis;
        position: absolute;
        top: 0;
        left: 0;

        &--invisible {
            left: -10000px;
        }
    }
}

.form-dropdown-editor-button--t-matrix {
    position: relative;
    border-bottom: 1px solid transparent;

    &:not(&--disabled):not(&--invalid):not(&--focused):hover {
        border-bottom: 1px solid #cacaca;
    }

    &--focused:not(&--invalid):not(&--warning) {
        border-bottom: 1px solid #4c8b5d;
    }

    &--invalid {
        border-bottom: 1px solid #f99178;
    }

    &--warning {
        border-bottom: 1px solid #ffc107;
    }

    &--loading::after {
        @include u-posAbsolute($top: 6px, $right: 7px, $width: 16px, $height: 16px);
        content: '';
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAArklEQVQ4y2NgwAGYmJgkgNRdEIaySQOMjIxeQOo/CEPZBMFCIP4KVBwC4rCysrIAqXYQhrJhBtswMzNzYDPgK9TG+QQsegwyBJuTQTbPB9IqJPuXIgAKYVAgIfuTVHAX6vd2YjUAA5EPSB0GWuxGuQHU8AJ1ALHRCHI20NVC2OQIJiRooIHUzMYmj5KUoRrCQBjGh9o8GyhmR9BLQMVyUNv+Q9mkAWisrAZhfDEEAA5cIiN3OTV6AAAAAElFTkSuQmCC');
        animation: form-dropdown-editor-button__loader-spin-360 1000ms infinite linear;
    }

    &__display-text {
        @include o-transparent-input();
        @include u-text-ellipsis();
        visibility: hidden;

        /* defines button height since input is absolute */
        &::after {
            content: ' ';
            display: inline-block;
        }

        &--visible {
            visibility: visible;
        }
    }

    /* IE11 hack, must mimic ordinary placeholder */
    &__input-placeholder {
        padding: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
        color: #a3a3a3;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        &--invalid {
            color: #923924;
        }

        &--warning {
            color: #ffc107;
        }
    }

    &__input {
        @include o-transparent-input();
        text-overflow: ellipsis;
        position: absolute;
        top: 0;
        left: 0;

        &::-ms-clear {
            display: none;
        }

        &--invisible {
            left: -10000px;
        }

        &--invalid::placeholder {
            color: #923924;
        }

        &--warning::placeholder {
            color: #ffc107;
        }
    }
}

.form-dropdown-editor-button--t-transparent {
    position: relative;

    &--loading::after {
        @include u-posAbsolute($top: 6px, $right: 7px, $width: 16px, $height: 16px);
        content: '';
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAArklEQVQ4y2NgwAGYmJgkgNRdEIaySQOMjIxeQOo/CEPZBMFCIP4KVBwC4rCysrIAqXYQhrJhBtswMzNzYDPgK9TG+QQsegwyBJuTQTbPB9IqJPuXIgAKYVAgIfuTVHAX6vd2YjUAA5EPSB0GWuxGuQHU8AJ1ALHRCHI20NVC2OQIJiRooIHUzMYmj5KUoRrCQBjGh9o8GyhmR9BLQMVyUNv+Q9mkAWisrAZhfDEEAA5cIiN3OTV6AAAAAElFTkSuQmCC');
        animation: form-dropdown-editor-button__loader-spin-360 1000ms infinite linear;
    }

    &__display-text {
        @include o-transparent-input();
        @include u-text-ellipsis();
        visibility: hidden;

        /* defines button height since input is absolute */
        &::after {
            content: ' ';
            display: inline-block;
        }

        &--visible {
            visibility: visible;
        }
    }

    /* IE11 hack, must mimic ordinary placeholder */
    &__input-placeholder {
        padding: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
        color: #a3a3a3;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        &--invalid {
            color: #923924;
        }

        &--warning {
            color: #ffc107;
        }
    }

    &__input {
        @include o-transparent-input();
        text-overflow: ellipsis;
        position: absolute;
        top: 0;
        left: 0;

        &::-ms-clear {
            display: none;
        }

        &--invisible {
            left: -10000px;
        }

        &--invalid::placeholder {
            color: #923924;
        }

        &--warning::placeholder {
            color: #ffc107;
        }
    }
}
