@import 'modules/styles/meta/index';

.reqf-add-file-button {
    position: relative;
    display: flex;
    height: 36px;
    color: #4c8a5c;
    width: max-content;

    &__content {
        display: flex;
        flex-grow: 1;
        align-items: center;
        @include font-standard-semibold(13px, currentColor);
    }

    &__paper-clip-icon {
        fill: currentColor;
        margin-right: 6px;
    }
}
