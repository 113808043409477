@import 'modules/styles/meta/index';

.company-select-subscription-subscriptions-header {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 60px;

    &__header-text {
        flex: 1;
        @include font-standard-semibold(18px, #333);
    }
}
