@import 'modules/styles/meta/index';

.no-inv-block {
    display: flex;
    flex-flow: column;
    align-items: center;

    &__no-env-title {
        @include font-standard-semibold(16px, #000);
        text-align: center;
        margin-top: 10px;
    }

    &__no-env-description {
        @include font-standard-semibold(12px, #000);
        text-align: center;
        margin-top: 20px;
    }
}
