//
// Definitions here represent the convention that allows class reuse and still keeps some order.
// By following it we at least can trace these unclear dependencies.
// You should not normally share classes between components, hence the cumbersome way to do this.
//

$data-row-hover-class: '.ui-data-row:hover';

@mixin data-row-cell-padding {
    padding: 0 4px 0 16px;

    &--padding-none {
        padding: 0;
    }

    &--padding-popup-left,
    &--padding-popup {
        padding-left: 80px;
    }

    &--padding-popup-right,
    &--padding-popup {
        padding-right: 60px;
    }

    &--left-60 {
        padding-left: 60px;
    }

    &--right-60 {
        padding-right: 60px;
    }
}
