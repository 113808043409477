@import 'modules/styles/meta/index';

.automation-dev-tools-popup {
    width: 800px;
    height: 600px;

    &__content {
        flex: 1;
        overflow: hidden;
        display: flex;
        padding: 0 60px 40px 0;
    }

    &__menu {
        display: flex;
        flex-flow: column;
        padding: 0 0 0 33px;
        width: 170px;
        @include o-popup-border-right();
    }

    &__menu-item {
        @include font-standard(15px, $color-forest-green);
        padding: 3px 3px 3px 8px;
        margin: 0 20px 10px 0;
        cursor: pointer;
        border: 1px solid transparent;

        &--selected {
            border-color: $color-forest-green;
        }
    }

    &__section {
        flex: 1;
        overflow: hidden;
        padding-left: 40px;
        display: flex;
        flex-flow: column;
    }

    &__section-content {
        flex: 1;
        overflow: hidden;
    }
}
