@import '../../../styles/meta/index';

%form-text-editor {
    text-overflow: ellipsis;
}

.form-text-editor--t-form {
    @extend %form-text-editor;
    @include o-form-input();

    &:focus:not(&--invalid) {
        color: #384650;
    }

    &:disabled {
        user-select: none;
        pointer-events: none;
    }
}

.form-text-editor--t-transparent {
    @extend %form-text-editor;
    @include u-transparent-input;

    &--invalid::placeholder {
        color: #923924;
    }
}

.form-text-editor--t-auth {
    @extend %form-text-editor;
    @include o-form-input();
    padding: 5px;
    @include font-standard(14px, #000);

    &:focus:not(&--invalid) {
        color: #384650;
    }

    &:disabled {
        user-select: none;
        pointer-events: none;
    }
}
