@import 'modules/styles/meta/index';

.reql-line-items-table {
    overflow-y: auto;

    &__table {
        min-width: 100%;
        margin-bottom: 10px;
    }

    &__table-head {
        border-bottom: 1px solid #d5cdcd;
    }

    &__table-head-col {
        padding: 18px 5px 5px 5px;
        @include font-standard-semibold(11px, #777d81);

        &:first-child {
            padding-left: 5px;
        }

        &:last-child {
            padding-right: 0;
        }

        &--align-right {
            text-align: right;
        }

        &--padding-top-small {
            padding-top: 8px;
        }

        &--required::after {
            content: '*';
            color: #cb4e30;
            margin-left: 3px;
        }
    }

    &__table-row {
        border-bottom: 1px solid #d5cdcd;

        &--disabled {
            background-color: #f3f3f3;
        }

        &--invalid:not(&--disabled) {
            background-color: #fff5f3;
        }
    }

    &__table-cell {
        @include font-standard(12px, #000);
        padding: 6px 5px 10px 5px;
        vertical-align: middle;

        &:first-child {
            padding-left: 5px;
        }

        &:last-child {
            padding-right: 0;
        }

        &--align-right {
            text-align: right;

            > div {
                margin-left: auto;
            }
        }

        &--disabled {
            background-color: #f3f3f3;
        }

        &--invalid:not(&--disabled) {
            background-color: #fff5f3;
        }
    }
}
