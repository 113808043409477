@import '../../styles/meta/index';

.form-field--t-form {
    display: flex;
    flex-flow: column;

    &__label {
        @include font-standard-semibold(12px, #5e5c5c);
        @include u-text-ellipsis();
        margin-bottom: 5px;

        &--bold-black {
            @include font-standard-semibold(12px, #000);
        }

        &--size-11 {
            font-size: 11px;
            margin-bottom: 5px;
        }

        &--wrap-label {
            white-space: initial;
        }
    }

    &--required &__label::after {
        content: '*';
        color: #cb4e30;
        margin-left: 3px;
    }

    &__error-text {
        margin-top: 5px;
        @include font-standard-semibold(12px, #a3341a);
    }
}

.form-field--t-form-horizontal {
    display: flex;
    align-items: center;

    &__label {
        @include font-standard-semibold(12px, #5e5c5c);
        @include u-text-ellipsis();
        width: 150px;
        flex: none;

        &--bold-black {
            @include font-standard-semibold(12px, #000);
        }

        &--size-11 {
            font-size: 11px;
            margin-bottom: 5px;
        }

        &--wrap-label {
            white-space: initial;
        }
    }

    &--required &__label::before {
        content: '*';
        color: #cb4e30;
        margin-right: 3px;
    }

    &__error-text {
        margin-top: 5px;
        @include font-standard-semibold(12px, #a3341a);
    }
}
