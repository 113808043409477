@import 'modules/styles/meta/index';

.common-ui-add-comment-editor {
    display: flex;
    gap: 15px;

    &__comment-editor-wrp {
        display: flex;
        flex: 1;
        width: calc(100% - 45px);
        justify-content: stretch;
        margin-top: -10px;

        & > div {
            width: 100%;
        }
    }

    &__comment-editor {
        width: 100%;
        box-shadow: none !important;
        outline: none !important;
        border-bottom: 2px solid #4c8b5d;
        border-radius: 0 !important;
    }
}
