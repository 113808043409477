@import 'modules/styles/meta/index';

.no-inv-block-profile-section {
    padding: 40px 40px 50px;
    margin: 30px 0 -10px;
    width: 680px;
    text-align: center;
    border: solid 1px #4c8a5c;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__go-text {
        @include font-standard-semibold(13px, #000);
        margin-bottom: 10px;
    }
    &__partner-portal-name {
        @include font-standard(24px, #000);
        margin-bottom: 30px;
    }

    &__icons {
        display: flex;
    }
    &__icon {
        margin: 0 10px;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
