@import '../../../styles/meta/index';

.form-radio-group-editor--t-form {
    display: flex;
    flex-flow: column;

    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
