@import 'modules/styles/meta/index';

.company-select-subscription-gray-area {
    background: #f3f3f3;
    @include font-standard(13px, #000);
    margin-bottom: 30px;
    padding: 15px 20px;

    &__header {
        @include font-standard-semibold(13px, #000);
        margin-bottom: 5px;
    }

    &__text {
        @include font-standard(12px, #000);
    }
}
