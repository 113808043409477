@import '../../../styles/meta/index';

@mixin multi-value-item($background, $backgroundHover) {
    @include font-standard();
    @include u-text-ellipsis();
    position: relative;
    height: 16px;
    padding: 0 13px 0 6px;
    line-height: 16px;
    font-size: 11px;
    border: 0;
    border-radius: 4px;
    cursor: default;
    color: #fff;
    background: $background;

    &--disabled {
        padding-right: 6px;
        background: #a0a1a1;
    }

    &:not(&--disabled):hover {
        background: $backgroundHover;
    }

    &__text {
        @include u-text-static();

        &--strike-through {
            text-decoration: line-through;
        }
    }

    &__remove-button {
        @include u-posAbsolute($top: 50%, $right: 4px);
        margin: -8px 0 0;
        color: #fff;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &:hover &__remove-button {
        opacity: 1;
        visibility: visible;
    }
}

.form-dropdown-editor-multi-value-item--t-green {
    @include multi-value-item($background: #649e84, $backgroundHover: #346c53);
}

.form-dropdown-editor-multi-value-item--t-grey {
    @include multi-value-item($background: #7e8890, $backgroundHover: #555);
}
