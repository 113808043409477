@import '../../styles/meta/index';

.ui-search-input--t-form {
    @include o-form-input();
    display: flex;
    align-items: center;

    &__icon {
        margin: 0 4px 0 1px;
    }

    &__input {
        @include u-transparent-input();
        flex: 1;
        cursor: pointer;

        &::-ms-clear {
            display: none;
        }
    }

    &--active &__input {
        cursor: inherit;
    }
}

.ui-search-input--t-transparent {
    @include o-transparent-input();
    display: flex;
    align-items: center;

    &__icon {
        margin: 1px 4px 0 1px;
    }

    &__input {
        @include u-transparent-input();
        flex: 1;
        cursor: pointer;

        &::-ms-clear {
            display: none;
        }
    }

    &--active &__input {
        cursor: inherit;
    }
}
