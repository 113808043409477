@import 'modules/styles/meta/index';

.rpt-date-range-filter {
    display: flex;
    align-items: center;
    @include font-standard(13px, #777d81);

    &__from-text {
        margin-left: 20px;
        margin-right: 5px;
    }

    &__dropdown {
        width: 130px;
    }

    &__from-editor {
        width: 110px;
    }

    &__to-text {
        margin: 0 5px;
    }

    &__to-editor {
        width: 110px;
    }
}
