@import '../../styles/meta/index';

@mixin addColorTheme($colorTheme, $color, $hoverColor: darken($color, 8%), $pressedColor: darken($color, 14%)) {
    &--color-#{$colorTheme} {
        color: $color;
    }

    &--color-#{$colorTheme}:hover:not(:disabled) {
        color: $hoverColor;
    }

    &:active#{&}--color-#{$colorTheme}:not(:disabled) {
        color: $pressedColor;
    }
}

@mixin addSize($sizeName, $size) {
    &--size-#{$sizeName} {
        font-size: $size;
    }
}

.ui-text-button {
    cursor: pointer;
    user-select: none;
    display: inline-block;
    background: transparent;
    font-weight: 600;
    transition: background 0.1s ease-in;

    &--upper-cased {
        text-transform: uppercase;
    }

    &--underlined {
        text-decoration: underline;
    }

    &:disabled {
        @include o-disabled();
        color: #a0a1a1;
    }

    &:focus {
        @include o-focus;
    }

    @include addColorTheme('red', #cb4e30);
    @include addColorTheme('forest-green', $color-forest-green, #2a5844, #2a5844);
    @include addColorTheme('dark-green', #477753);
    @include addColorTheme('dark-grey', #565656);
    @include addColorTheme('white', #fff);
    @include addColorTheme('black', #000);
    @include addColorTheme('green', #477753);

    @include addSize('small2', 11px);
    @include addSize('small1', 12px);
    @include addSize('normal', 13px);
    @include addSize('normal-plus', 14px);
    @include addSize('large', 16px);
}
