@import 'modules/styles/meta/index';

.form-dropdown-editor-company-user-list-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1px solid #ebeaea;
    }

    &:hover {
        background: #f5f5f5;
    }

    &--active {
        background: #f5f5f5;
    }

    &__text {
        flex: 1;
        @include font-standard(12px, #000);
        @include u-text-ellipsis();
        line-height: 18px;
        padding-left: 10px;
    }

    &__highlighted-text {
        background-color: #edecd0;
    }

    &__invitation-badge {
        @include font-standard(12px, #777);
        padding-left: 10px;
        line-height: 18px;
    }
}
