@import 'modules/styles/meta/index';

.reql-workflow-section {
    padding: 10px 0 30px 0;
    display: flex;
    overflow-x: auto;

    &__step-container {
        display: flex;
        flex-flow: column;
        overflow: hidden;
        min-width: 80px;

        &--selected {
            flex: none;
        }
    }

    &__step-tooltip {
        padding: 7px 12px 12px;
    }

    &__step-tooltip-name {
        @include font-standard(12px, #fff);
        margin-bottom: 4px;
    }

    &__step-tooltip-status {
        @include font-standard-semibold(12px, #fff);
    }

    &__step-bubble-row {
        display: flex;
        align-items: center;
    }

    &__step-bubble-wrp {
        overflow: hidden;
        width: 100%;
    }

    &__step-bubble {
        height: 30px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px 0 15px;
        border-radius: 15px;
        @include font-standard(14px);
        user-select: none;
        cursor: pointer;

        /* Step is selected by the user */

        &--selected {
            min-width: 210px;
        }

        /* Request Resolution */

        &--request-approved {
        }

        &--request-rejected {
        }

        &--request-cancelled {
        }

        /* Step State/Resolution + locked */

        /* Approved */

        &--approved {
            background: #7db48b;
            color: #fff;
            justify-content: flex-start;

            &:hover {
                background: #4c8a5c;
            }
        }

        &--selected#{&}--approved {
            background: #4c8a5c;
        }

        /* Rejected */

        &--rejected {
            background: #e8866f;
            color: #fff;

            &:hover {
                background: #d85d3f;
            }
        }

        &--selected#{&}--rejected {
            background: #d85d3f;
        }

        /* Active */

        &--active {
            border: 1px solid #77aa84;
            color: #477753;

            &:hover {
                border-color: #565656;
            }
        }

        &--locked#{&}--active {
            border: 1px solid #e8e8e8;
            background: #e8e8e8;
            color: #565656;

            &:hover {
                border: 1px solid #a0a1a1;
            }
        }

        &--selected#{&}--locked#{&}--active {
            border-color: #d6cfcf;
            background: #d6cfcf;

            &:hover {
                border-color: #a0a1a1;
            }
        }

        /* Not started */

        &--not-started {
            border: 1px solid #cacccd;
            color: #777d81;

            &:hover {
                border-color: #565656;
            }
        }

        &--selected#{&}--not-started {
            border-color: #565656;
        }

        &--locked#{&}--not-started {
            border: 1px solid #e8e8e8;
            background: #e8e8e8;
            color: #565656;

            &:hover {
                border: 1px solid #a0a1a1;
            }
        }

        &--selected#{&}--locked#{&}--not-started {
            border-color: #d6cfcf;
            background: #d6cfcf;

            &:hover {
                border-color: #a0a1a1;
            }
        }
    }

    &__step-name {
        @include u-text-ellipsis();
    }

    &__step-approved-icon {
        margin-top: 2px;
        margin-right: 4px;
        flex: none;
    }

    &__step-not-started-text {
        @include font-standard-semibold(11px, #777d81);
        text-transform: uppercase;
        margin-left: 10px;
        text-align: right;
        white-space: nowrap;
    }

    &__step-active-text {
        @include font-standard-semibold(11px);
        text-transform: uppercase;
        margin-left: 10px;
        text-align: right;
        white-space: nowrap;
    }

    &__step-active-icon {
        border-radius: 5px;
        margin-left: 10px;
        height: 10px;
        width: 10px;
        background: #6ba67a;
        flex: none;
    }

    &__step-separator-icon {
        margin: 0 6px 0 5px;
        flex: none;
    }

    &__step-auto-approved {
        display: flex;
        align-items: center;
        @include font-standard(13px, $color-forest-green);
    }

    &__step-auto-approved-icon {
        margin-right: 6px;
    }

    &__step-details {
        display: flex;
        flex-flow: column;
        margin-right: 18px;
    }

    &__step-type {
        @include font-standard(11px, #6c6a6a);
        text-align: center;
        margin-top: 5px;
    }

    &__step-participant-list {
        display: flex;
        flex-flow: wrap;
        margin-top: 20px;

        & > *:not(:last-child) {
            margin-right: 6px;
        }
    }

    &__step-participant {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        position: relative;
    }

    &__step-participant-badge {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 20px;
        top: -6px;
        z-index: 1;
        border-radius: 9px;
        border: 1px solid #fff;

        &--approved {
            background: #fff;
        }

        &--rejected {
            background: #d86767;
            @include u-flexCenter();
        }
    }

    &__step-participant-avatar {
    }

    &__step-participant-name {
        @include font-standard(13px, #30373d);
        @include u-text-ellipsis();
        margin-left: 6px;

        &--hide {
            display: none;
        }
    }

    &__step-edit-button {
        fill: #4c8a5c;

        &:hover {
            fill: #2a5844;
        }
    }

    &__step-reassign-button {
        fill: #4c8a5c;

        &:hover {
            fill: #2a5844;
        }
    }
}
