@import '../../styles/meta/index';
@import 'index';

.ui-data-table-default-column-header {
    @include font-standard-semibold();
    font-size: 13px;
    color: #565656;
    display: flex;
    align-items: center;
    fill: currentColor;
    user-select: none;
    cursor: default;

    @include data-row-cell-padding();

    &--sortable {
        cursor: pointer;

        &:hover {
            color: #4d9a4d;
        }
    }

    &__text {
        text-transform: uppercase;
        margin-right: 4px;
    }

    &__sort-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);
        opacity: 1;
        transition: all 300ms ease-in-out;

        &--desc {
            transform: rotate(0);
        }

        &--hidden {
            opacity: 0;
        }
    }
}
