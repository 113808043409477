@import 'modules/styles/meta/index';

.pending-practice-invitation-row {
    height: 60px;
    flex-direction: row-reverse;

    &__text {
        display: flex;
        flex-direction: column;

        &--disabled {
            color: #5e5c5c;
        }
    }

    &__company-name {
        @include font-standard-bold(14px);
        line-height: 20px;
    }

    &__invitation-owner {
        @include font-standard(12px);
        line-height: 16px;
    }
}
